import { render, staticRenderFns } from "./customswitch.vue?vue&type=template&id=23f0ef7a&scoped=true"
import script from "./customswitch.vue?vue&type=script&lang=js"
export * from "./customswitch.vue?vue&type=script&lang=js"
import style0 from "./customswitch.vue?vue&type=style&index=0&id=23f0ef7a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f0ef7a",
  null
  
)

export default component.exports