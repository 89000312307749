<template>
  <div class="h100 flex flex-column justify-center gridbox-element">
    <div
      :class="`h0 center ${
        invertedColors ? 'color-black' : 'color-white'
      } open-sans-bold mb3`"
    >
      {{ $t("iot_loader_label") }}
    </div>
    <div class="loader-circle">
      <svg viewBox="0 0 250 90.97">
        <g>
          <path
            d="M219.13,12.38c-7.77-6.08-15.79-12.38-31.63-12.38s-23.86,6.3-31.63,12.38c-7.95,6.24-15.46,12.12-30.87,12.12s-22.92-5.88-30.87-12.12C86.36,6.3,78.34,0,62.5,0s-23.86,6.3-31.63,12.38c-7.95,6.24-15.46,12.12-30.87,12.12V90.97H250V24.51c-15.41,0-22.92-5.88-30.87-12.12Z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: {
    invertedColors: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.loader-circle {
  width: 108px;
  aspect-ratio: 1/1;
  border-radius: 999px;
  overflow: hidden;
  background-color: #93b7fe;
  margin: 0 auto;
  position: relative;
}
.loader-circle svg {
  position: absolute;
  bottom: -20px;
  width: 216px;
  animation: 1.25s load;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loader-circle svg path {
  fill: #6037ff;
}

@keyframes load {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-108px);
  }
}
</style>
