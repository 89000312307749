var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"tab-switch-wrapper",staticClass:"tab-switch-wrapper flex col-12 justify-center py2"},[_c('div',{staticClass:"myrecipe-tab-switch flex justify-center"},[_c('div',{ref:"tab-1",class:`p2 px2 w-fit-content pointer relative z3 h6 ${
          _vm.activeTab == 1
            ? _vm.invertedColors
              ? 'color-black open-sans-bold'
              : 'color-white open-sans-bold'
            : _vm.invertedColors
            ? 'color-13 open-sans-regular'
            : 'color-12 open-sans-regular'
        }`,on:{"click":function($event){_vm.activeTab = 1;
          _vm.moveIndicator(1);}}},[_vm._v(" "+_vm._s(_vm.slide1Heading)+" ")]),_c('div',{ref:"tab-2",class:`p2 px2 w-fit-content pointer relative z3 h6 ${
          _vm.activeTab == 2
            ? _vm.invertedColors
              ? 'color-black open-sans-bold'
              : 'color-white open-sans-bold'
            : _vm.invertedColors
            ? 'color-13 open-sans-regular'
            : 'color-12 open-sans-regular'
        }`,on:{"click":function($event){_vm.activeTab = 2;
          _vm.moveIndicator(2);}}},[_vm._v(" "+_vm._s(_vm.slide2Heading)+" ")]),_c('div',{ref:"tab-indicator",class:`active-tab-indicator ${
          _vm.invertedColors ? 'active-tab-dark' : 'active-tab-light'
        } ${_vm.activeTab == 2 ? 'move-right' : ''}`}),_c('div',{class:`tab-rail ${
          _vm.invertedColors ? 'tab-rail-dark' : 'tab-rail-light'
        }`})])]),_vm._t("additional-top-content"),_c('div',{staticClass:"myrecipe-tab-wrapper gridbox col-12 overflow-hidden"},[_c('div',{class:`myrecipe-tab-slide slide-1 gridbox-element ${
        _vm.activeTab == 1 ? 'active' : ''
      }`},[_vm._t("slide-1")],2),_c('div',{class:`myrecipe-tab-slide slide-2 gridbox-element ${
        _vm.activeTab == 2 ? 'active' : ''
      }`},[_vm._t("slide-2")],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }