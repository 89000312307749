<template>
  <div ref="iot_input" class="h100 gridbox-element">
    <!-- LED Flow -->
    <Transition name="fade">
      <div v-if="isLEDFlow == '1' && !isLoading" class="flex flex-column h100 justify-between">
        <div class="flex flex-column" style="flex-grow: 1">
          <h2 :class="`${invertedColors ? 'color-black' : 'color-white'} h0 open-sans-bold center mb-mb4 mb1`">
            {{ flow.question }}
          </h2>
          <tabcarousel :slide1Heading="$t('iot_red_sea_system_select_placeholder')" :slide2Heading="$t('iot_tab_heading_custom_tank')" :invertedColors="invertedColors">
            <!-- RSF Aquarium -->
            <div slot="slide-1">
              <div>
                <div class="iot-select-wrapper mx-auto mb3 relative">
                  <svg class="pointer-events-none" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7695 1.5L5.88481 6.5L1.00008 1.5" :stroke="invertedColors ? 'black' : 'white'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <select :class="`iot-select h5 open-sans-regular pointer ${invertedColors ? 'color-14 iot-select-dark bg-color-white' : 'iot-select-light color-white'}`" v-model="selectedTank">
                    <option :value="{}" disabled isSelected>
                      {{ $t("iot_red_sea_system_select_placeholder") }}
                    </option>
                    <option v-for="tank in flow.tankSelection" :key="tank.RNumberOfTank" :value="tank">
                      {{ tank.Model }}
                    </option>
                  </select>
                </div>
                <p class="center color-13 h6 open-sans-regular mb3">
                  {{ $t("iot_g2_g2p_notice") }}
                </p>
                <div @click="displayOutput('rsfTank')" :class="`rsf-btn rsf-btn-primary bg-color-1 mx-auto h5 color-white open-sans-regular mb3 ${Object.keys(selectedTank).length == 0 ? 'inactive' : ''}`">
                  {{ $t("iot_btn_label_show_results") }}
                </div>
              </div>
            </div>
            <!-- Custom Aquarium -->
            <div slot="slide-2">
              <div>
                <div v-if="measurement == 'metric'" class="iot-input-wrapper mx-auto flex flex-column gap1 mb2">
                  <!-- <input type="number" class="iot-input h5 color-white open-sans-regular pointer border-box" placeholder="Aquarium height" v-model="width">
                  <input type="number" class="iot-input h5 color-white open-sans-regular pointer border-box" placeholder="Aquarium width" v-model="length"> -->
                  <div class="iot-select-wrapper mx-auto relative col-12">
                    <svg class="pointer-events-none" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.7695 1.5L5.88481 6.5L1.00008 1.5" :stroke="invertedColors ? 'black' : 'white'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <select ref="iot_select_width_metric" :class="`iot-select ${invertedColors ? 'iot-select-dark color-14 bg-color-white' : 'iot-select-light color-white'} h5 open-sans-regular pointer`" v-model="width">
                      <option :value="0" disabled isSelected>
                        {{ $t("iot_width_select_placeholder") }}
                      </option>
                      <option :value="50">50cm</option>
                      <option :value="57">57cm</option>
                      <option :value="65">65cm</option>
                      <option :value="80">80cm</option>
                    </select>
                  </div>
                  <div class="iot-select-wrapper mx-auto relative col-12">
                    <svg class="pointer-events-none" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.7695 1.5L5.88481 6.5L1.00008 1.5" :stroke="invertedColors ? 'black' : 'white'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <select ref="iot_select_length_metric" :class="`iot-select h5 open-sans-regular pointer ${invertedColors ? 'color-14 iot-select-dark bg-color-white' : 'iot-select-light color-white'}`" v-model="length">
                      <option :value="0" disabled isSelected>
                        {{ $t("iot_length_select_placeholder") }}
                      </option>
                      <option :value="50">50cm</option>
                      <option :value="60">60cm</option>
                      <option :value="90">90cm</option>
                      <option :value="120">120cm</option>
                      <option :value="150">150cm</option>
                      <option :value="180">180cm</option>
                      <option :value="200">200cm</option>
                      <option :value="225">225cm</option>
                    </select>
                  </div>
                </div>
                <div v-if="measurement == 'imperial'" class="iot-input-wrapper mx-auto mb2 flex flex-column gap1">
                  <!-- <input type="number" class="iot-input h5 color-white open-sans-regular pointer border-box" placeholder="Aquarium height" v-model="width">
                  <input type="number" class="iot-input h5 color-white open-sans-regular pointer border-box" placeholder="Aquarium width" v-model="length"> -->
                  <div class="iot-select-wrapper mx-auto relative col-12">
                    <svg class="pointer-events-none" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.7695 1.5L5.88481 6.5L1.00008 1.5" :stroke="invertedColors ? 'black' : 'white'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <select ref="iot_select_width_imperial" :class="`iot-select h5 open-sans-regular pointer ${invertedColors ? 'color-14 iot-select-dark bg-color-white' : 'iot-select-light color-white'}`" v-model="width">
                      <option :value="0" disabled isSelected>
                        {{ $t("iot_width_select_placeholder") }}
                      </option>
                      <option :value="50">20"</option>
                      <option :value="57">22.6"</option>
                      <option :value="65">25.6"</option>
                      <option :value="80">32"</option>
                    </select>
                  </div>
                  <div class="iot-select-wrapper mx-auto relative col-12">
                    <svg class="pointer-events-none" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.7695 1.5L5.88481 6.5L1.00008 1.5" :stroke="invertedColors ? 'black' : 'white'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <select ref="iot_select_length_imperial" :class="`iot-select h5 open-sans-regular pointer ${invertedColors ? 'color-14 iot-select-dark bg-color-white' : 'iot-select-light color-white'}`" v-model="length">
                      <option :value="0" disabled isSelected>
                        {{ $t("iot_length_select_placeholder") }}
                      </option>
                      <option :value="50">20"</option>
                      <option :value="60">24"</option>
                      <option :value="90">36"</option>
                      <option :value="120">47"</option>
                      <option :value="150">59"</option>
                      <option :value="180">71"</option>
                      <option :value="200">79"</option>
                      <option :value="225">89"</option>
                    </select>
                  </div>
                </div>
                <p class="center color-13 h6 open-sans-regular mb3">
                  <span class="open-sans-bold">{{ $t("iot_note") }}</span>
                  {{ $t("iot_water_depth_notice") }}
                </p>
                <customswitch label1="cm" label2="inch" state1="metric" state2="imperial" :value="measurement" ebEvent="update_measurement" :invertedColors="invertedColors" />
                <div @click="displayOutput('custom')" :class="`rsf-btn rsf-btn-primary bg-color-1 mx-auto h5 color-white open-sans-regular mb3 mt3 ${width <= 0 || length <= 0 ? 'inactive' : ''}`">
                  {{ $t("iot_btn_label_show_results") }}
                </div>
              </div>
            </div>
          </tabcarousel>
        </div>
        <p :class="`h6 open-sans-regular center ${invertedColors ? 'color-14' : 'color-white'}`">
          {{ $t("iot_see_all") }}
          <a class="open-sans-bold underline" target="_blank" :href="tableLink" style="text-decoration-thickness: 1px">{{ $t("iot_compatibility_charts_line") }}</a>
        </p>
      </div>
    </Transition>

    <!-- Other Product Flow -->
    <Transition name="fade">
      <div class="h100 flex flex-column justify-between" v-if="isLEDFlow != '1' && !isLoading">
        <div>
          <h2 class="color-white h0 open-sans-bold center mb4">
            {{ flow.question }}
          </h2>
          <div class="iot-select-wrapper mx-auto mb3 relative">
            <svg class="pointer-events-none" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7695 1.5L5.88481 6.5L1.00008 1.5" :stroke="invertedColors ? 'black' : 'white'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <select :class="`iot-select h5 open-sans-regular pointer ${invertedColors ? 'color-14 iot-select-dark' : 'iot-select-light color-white'}`" v-model="selectedTank">
              <option :value="{}" disabled isSelected>
                {{ $t("iot_red_sea_system_select_placeholder") }}
              </option>
              <option v-for="tank in flow.tankSelection" :key="tank.RNumberOfTank" :value="tank">
                {{ tank.Model }}
              </option>
            </select>
          </div>
          <p class="center color-white h6 open-sans-regular mb3">
            {{ $t("iot_g2_g2p_notice") }}
          </p>
          <div @click="displayOutput('rsfTank')" :class="`rsf-btn rsf-btn-primary bg-color-1 mx-auto h5 color-white open-sans-regular mb3 ${Object.keys(selectedTank).length == 0 ? 'inactive' : ''}`">
            {{ $t("iot_btn_label_show_results") }}
          </div>
        </div>
        <p :class="`h6 ${invertedColors ? 'color-black' : 'color-white'} open-sans-regular center`">
          {{ $t("iot_see_all") }}
          <a class="open-sans-bold underline" target="_blank" :href="tableLink" style="text-decoration-thickness: 1px">{{ $t("iot_compatibility_charts_line") }}</a>
        </p>
      </div>
    </Transition>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import axios from "axios";
import tabcarousel from "./tabcarousel.vue";
import customswitch from "./customswitch.vue";

export default {
  components: { tabcarousel, customswitch },
  name: "iot_input",
  props: {
    iotId: String,
    flow: Object,
    siteLanguage: String,
    isLEDFlow: String,
    measurement: String,
    tableLink: String,
    invertedColors: Boolean,
  },
  data() {
    return {
      isLoading: true,
      selectedTank: {},
      width: 0,
      length: 0,
    };
  },
  mounted() {
    this.fetchData(this.iotId);

    console.log("input mounted... ledFlow: ");
    console.log(this.isLEDFlow);
  },
  methods: {
    fetchData(iotId) {
      console.log("fetching data...");
      let url = `/public-api/iot/get-tanks/${iotId}/${this.siteLanguage}/json`;
      axios
        .get(process.env.VUE_APP_RSF_API + url)
        .then((response) => {
          EventBus.$emit("eb_log", this.$options.name, "update_flow", "emit");
          EventBus.$emit("update_flow", response.data);
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    displayOutput(mode) {
      console.log(this.$refs[`iot_select_width_${this.measurement}`]);
      if (mode == "rsfTank") {
        EventBus.$emit("eb_log", this.$options.name, "update_selectedTank", "emit");
        EventBus.$emit("update_selectedTank", this.selectedTank);
      } else if (mode == "custom") {
        EventBus.$emit("eb_log", this.$options.name, "update_customTankDimensions", "emit");
        EventBus.$emit("update_customTankDimensions", {
          width: this.width,
          displayWidth: this.$refs[`iot_select_width_${this.measurement}`].options[this.$refs[`iot_select_width_${this.measurement}`].selectedIndex].text,
          length: this.length,
          displayLength: this.$refs[`iot_select_length_${this.measurement}`].options[this.$refs[`iot_select_length_${this.measurement}`].selectedIndex].text,
        });
      }
    },
  },
};
</script>

<style scoped>
.iot-input {
  background-color: transparent;
  outline: none;
}

/* Hide numbers */
/* Chrome, Safari, Edge, Opera */
.iot-input::-webkit-outer-spin-button,
.iot-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.iot-input[type="number"] {
  -moz-appearance: textfield;
}
.iot-select,
.iot-input {
  padding: 1rem 4rem 1rem 2rem;
  border-radius: 999px;
  width: 100%;
  height: 54px;
}

.iot-select-light {
  border: 1px solid white;
}
.iot-select-dark {
  border: 1px solid var(--rsf-2022-color-12);
}

.iot-select-light option {
  color: var(--rsf-2022-color-black) !important;
}
.iot-select-wrapper,
.iot-input-wrapper {
  max-width: 400px;
}
.iot-select-wrapper svg {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}
.rsf-btn.rsf-btn-primary.inactive {
  opacity: 0.5;
  pointer-events: none;
}
</style>
