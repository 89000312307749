import input from './components/input.vue'
import output from './components/output.vue'

export default [
    {
        path: '/:lang', 
        component: input,
    },
    { 
        path: '/:lang/output', 
        component: output,
    },
    {
        path: "*",
        redirect: "/en",
        component: input,
    }
]