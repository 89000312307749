<template>
  <div
    :class="` ${
      invertedColors ? 'rsf-custom-switch-light' : ''
    } rsf-custom-switch gridbox gap1 justify-betweem pointer ${
      invertedColors ? '' : 'bg-color-black'
    }`"
    @click="toggleSwitch()"
  >
    <span
      :class="`${value == state2 ? 'op0' : ''} gridbox-element open-sans-bold ${
        invertedColors ? 'color-13' : 'color-13'
      } col-12 border-box pr2`"
      style="font-size: 12px"
      >{{ label2 }}</span
    >
    <span
      :class="`${value == state1 ? 'op0' : ''} gridbox-element open-sans-bold ${
        invertedColors ? 'color-13' : 'color-13'
      } right-align col-12 border-box`"
      style="padding-right: 4px; font-size: 12px"
      >{{ label1 }}</span
    >
    <div
      :class="`rsf-custom-switch-thumb ${
        invertedColors ? 'rsf-custom-switch-thumb-light' : ''
      }`"
      :style="`left:${value == state2 ? '-19px' : 'calc(100% - 20px)'}`"
    >
      <span
        :class="`h6 open-sans-bold ${
          invertedColors ? 'color-1' : 'color-white'
        }`"
        style="font-size: 12px"
        >{{ value == state2 ? label2 : label1 }}</span
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  name: "customswitch",
  data() {
    return {
      useCm: true,
    };
  },
  props: {
    label1: String,
    label2: String,
    state1: String,
    state2: String,
    value: String,
    ebEvent: String,
    invertedColors: Boolean,
  },
  methods: {
    toggleSwitch() {
      EventBus.$emit("eb_log", this.$options.name, this.ebEvent, "emit");
      EventBus.$emit(this.ebEvent, {
        currentValue: this.value,
        state1: this.state1,
        state2: this.state2,
      });
    },
  },
};
</script>

<style scoped>
.rsf-custom-switch {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  padding: 3px 7px;
  border: 1px solid var(--rsf-2022-color-13);
  border-radius: 999px;
  box-sizing: border-box;
}

.rsf-custom-switch.rsf-custom-switch-light {
  border: none;
  background-color: #e7edf0;
}

.rsf-custom-switch-thumb {
  height: 36px;
  aspect-ratio: 1 / 1;
  position: absolute;
  border-radius: 999px;
  border: 1px solid var(--rsf-2022-color-13);
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--rsf-2022-color-1);
  transition: 0.3s ease;
}

.rsf-custom-switch-thumb.rsf-custom-switch-thumb-light {
  border: none;
  background-color: var(--rsf-2022-color-white);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}

.rsf-custom-switch-thumb span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -1px;
}
</style>
