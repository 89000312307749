<template>
  <div>
    <div
      ref="tab-switch-wrapper"
      class="tab-switch-wrapper flex col-12 justify-center py2"
    >
      <div class="myrecipe-tab-switch flex justify-center">
        <div
          ref="tab-1"
          :class="`p2 px2 w-fit-content pointer relative z3 h6 ${
            activeTab == 1
              ? invertedColors
                ? 'color-black open-sans-bold'
                : 'color-white open-sans-bold'
              : invertedColors
              ? 'color-13 open-sans-regular'
              : 'color-12 open-sans-regular'
          }`"
          @click="
            activeTab = 1;
            moveIndicator(1);
          "
        >
          {{ slide1Heading }}
        </div>
        <div
          ref="tab-2"
          :class="`p2 px2 w-fit-content pointer relative z3 h6 ${
            activeTab == 2
              ? invertedColors
                ? 'color-black open-sans-bold'
                : 'color-white open-sans-bold'
              : invertedColors
              ? 'color-13 open-sans-regular'
              : 'color-12 open-sans-regular'
          }`"
          @click="
            activeTab = 2;
            moveIndicator(2);
          "
        >
          {{ slide2Heading }}
        </div>
        <div
          ref="tab-indicator"
          :class="`active-tab-indicator ${
            invertedColors ? 'active-tab-dark' : 'active-tab-light'
          } ${activeTab == 2 ? 'move-right' : ''}`"
        ></div>
        <div
          :class="`tab-rail ${
            invertedColors ? 'tab-rail-dark' : 'tab-rail-light'
          }`"
        ></div>
      </div>
    </div>
    <slot name="additional-top-content"></slot>
    <div class="myrecipe-tab-wrapper gridbox col-12 overflow-hidden">
      <div
        :class="`myrecipe-tab-slide slide-1 gridbox-element ${
          activeTab == 1 ? 'active' : ''
        }`"
      >
        <slot name="slide-1"></slot>
      </div>
      <div
        :class="`myrecipe-tab-slide slide-2 gridbox-element ${
          activeTab == 2 ? 'active' : ''
        }`"
      >
        <slot name="slide-2"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab_Carousel",
  props: {
    slide1Heading: String,
    slide2Heading: String,
    invertedColors: Boolean,
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs["tab-indicator"].style.width =
        this.$refs["tab-1"].clientWidth + "px";
    }, 300);
  },
  methods: {
    moveIndicator(tab) {
      this.$refs["tab-indicator"].style.width =
        this.$refs["tab-" + tab].clientWidth + "px";
      if (tab == 2) {
        this.$refs["tab-indicator"].style.left = `calc(100% - ${
          this.$refs["tab-" + tab].clientWidth + "px"
        })`;
      } else {
        this.$refs["tab-indicator"].style.left = 0;
      }
    },
  },
};
</script>
<style scoped>
.myrecipe-tab-switch {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 1rem;
  position: relative;
}

.active-tab-indicator {
  display: block;
  position: absolute;
  width: 30px;
  height: 3px;
  z-index: 2;
  bottom: 0;
  left: 0;
  border-radius: 999px;
  transition: 0.3s ease;
}

.active-tab-light {
  background-color: white;
}

.active-tab-dark {
  background-color: black;
}

.myrecipe-tab-slide {
  transition: 0.3s ease;
  opacity: 0;
}

.myrecipe-tab-slide.slide-1 {
  transform: translateX(-100%);
}

.myrecipe-tab-slide.slide-1.active {
  transform: translateX(0);
}

.myrecipe-tab-slide.slide-2 {
  transform: translateX(100%);
}

.myrecipe-tab-slide.slide-2.active {
  transform: translateX(0);
}

.myrecipe-tab-slide.active {
  opacity: 1;
}

.tab-switch-wrapper {
  position: sticky;
  top: 0;
  z-index: 99;
}

.tab-rail {
  position: absolute;
  bottom: 1px;
  height: 1px;
  width: 100%;
}

.tab-rail-light {
  background-color: var(--rsf-2022-color-12);
}
.tab-rail-dark {
  background-color: var(--rsf-2022-color-13);
}
</style>
