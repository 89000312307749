var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:` ${
    _vm.invertedColors ? 'rsf-custom-switch-light' : ''
  } rsf-custom-switch gridbox gap1 justify-betweem pointer ${
    _vm.invertedColors ? '' : 'bg-color-black'
  }`,on:{"click":function($event){return _vm.toggleSwitch()}}},[_c('span',{class:`${_vm.value == _vm.state2 ? 'op0' : ''} gridbox-element open-sans-bold ${
      _vm.invertedColors ? 'color-13' : 'color-13'
    } col-12 border-box pr2`,staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.label2))]),_c('span',{class:`${_vm.value == _vm.state1 ? 'op0' : ''} gridbox-element open-sans-bold ${
      _vm.invertedColors ? 'color-13' : 'color-13'
    } right-align col-12 border-box`,staticStyle:{"padding-right":"4px","font-size":"12px"}},[_vm._v(_vm._s(_vm.label1))]),_c('div',{class:`rsf-custom-switch-thumb ${
      _vm.invertedColors ? 'rsf-custom-switch-thumb-light' : ''
    }`,style:(`left:${_vm.value == _vm.state2 ? '-19px' : 'calc(100% - 20px)'}`)},[_c('span',{class:`h6 open-sans-bold ${
        _vm.invertedColors ? 'color-1' : 'color-white'
      }`,staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(_vm.value == _vm.state2 ? _vm.label2 : _vm.label1))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }