<template>
  <div class="h100 flex flex-column justify-center gridbox-element">
    <div class="h100 flex flex-column justify-between">
      <div></div>
      <div>
        <p v-if="recommendations.length > 0" v-for="(recommendation, index) in recommendations" :key="index" :class="`h1 center ${invertedColors ? 'color-black' : 'color-white'} open-sans-bold mb1`">
          {{ index > 0 && index != recommendations.length + 1 ? $t("iot_recommendation_or") : "" }}
          {{ recommendation.Amount }}
          {{ recommendation.Amount > 1 ? $t("iot_recommendation_unit_plural") : $t("iot_recommendation_unit_singular") }}
          {{ recommendation.Model }}
        </p>
        <p v-if="recommendations.length == 0" :class="`h1 center ${invertedColors ? 'color-black' : 'color-white'} open-sans-bold mb1`">
          {{ $t("iot_recommendation_no_product_found") }}
        </p>
        <p :class="`open-sans-semibold h5 center ${invertedColors ? 'color-14' : 'color-white'}`">
          {{ $t("iot_recommendation_for_your") }}
          {{ $route.query.customTankWidth == "0" ? selectedTank.Model : $t("iot_recommendation_tank") }}
        </p>
        <p v-if="$route.query.customTankWidth != '0'" :class="`open-sans-semibold h5 center ${invertedColors ? 'color-14' : 'color-white'}`">({{ $t("iot_recommendation_width") + " " + $route.query.displayTankWidth }}, {{ $t("iot_recommendation_length") + " " + $route.query.displayTankLength }})</p>
        <!-- disclaimer -->
        <p v-if="disclaimer && showDisclaimer" :class="`center h5 open-sans-regular col-12 mt4 ${invertedColors ? 'color-14' : 'color-white'}`">
          {{ disclaimer }}
        </p>
        <div class="flex flex-column sm-flex gap3 items-center justify-center mt4">
          <a :href="`https://g1.redseafish.com/${siteLanguage}/super-store-finder/`" class="rsf-btn bg-color-1 rsf-btn-primary color-white open-sans-semibold h5">{{ $t("iot_recommendation_find_nearest_dealer") }}</a>
          <router-link :to="`/${$i18n.locale}`" class="rsf-btn rsf-btn-tertiary bg-color-transparent color-1 open-sans-semibold h5">{{ $t("iot_recommendation_start_over") }}</router-link>
        </div>
      </div>
      <p :class="`h6 ${invertedColors ? 'color-14' : 'color-white'} open-sans-regular center mt4`">
        {{ $t("iot_see_all") }}
        <a class="open-sans-bold underline" target="_blank" :href="tableLink" style="text-decoration-thickness: 1px">{{ $t("iot_compatibility_charts_line") }}</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "iot_output",
  props: {
    siteLanguage: String,
    iotId: String,
    customTankIotId: String,
    recommendations: Array,
    selectedTank: Object,
    flow: Object,
    tableLink: String,
    measurement: String,
    invertedColors: Boolean,
    disclaimer: String,
  },
  data() {
    return {};
  },
  mounted() {
    if (this.$route.query.flowType == undefined) {
      console.log("No query params set, go back to input.");
      this.$router.push({ path: "/" + this.siteLanguage });
    }
  },
  methods: {},
  computed: {
    // unit(){
    //   if(this.measurement == 'metric'){
    //     return 'cm'
    //   }else{
    //     return '"'
    //   }
    // }
    showDisclaimer() {
      for (let i = 0; i < this.recommendations.length; i++) {
        if (this.recommendations[i].RNumber == "R35110") {
          //ReefLED 50
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped></style>
